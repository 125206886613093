import React from 'react';
import Layout from '../components/Layout';
import {Link} from 'gatsby';
import Dibujo3d from "../../static/images/products/9/maquina.jpg";

import Uno from "../../static/images/products/9/img16.jpg"
import Dos from "../../static/images/products/9/img17.jpg"
import Tres from "../../static/images/products/9/img18.jpg"
import Cuatro from "../../static/images/products/9/img19.jpg"
import Cinco from "../../static/images/products/9/img20.jpg"
import Seis from "../../static/images/products/9/img21.jpg"
import Siete from "../../static/images/products/9/img22.jpg"

const Service_one = ({location}) => {
    const {state = {}} = location;
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div
                        className="services_banner"
                        style={{
                            backgroundImage: `url(../images/products/9/maquina.jpg)`,
                        }}
                    >
                        <h1 style={{color: "#D7DBDD"}}>Línea de reciclado de películas por granulación y extrusión de
                            tres funciones en una</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">
                            Configuración del Equipo y Parámetros Técnicos Relevantes.
                        </h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: "black"}}>
                                1.1) Denominación: Línea de reciclado por granulación y extrusión de películas de tres
                                funciones en una.
                                <br/>
                                1.2) Modelo: HXSJ140(30)/140-TR900.
                                <br/>
                                1.3) Materia prima: Películas de PE, LDPE, HDPE, LLDPE, para reciclado.
                                <br/>
                                1.4) Capacidad de Producción: 400-500 Kg/h.
                            </p>
                        </div>
                    </div>

                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Características Técnicas Principales del Equipo</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: "black"}}>
                                Este equipo está especialmente diseñado para la granulación y reciclado de Polietilenos
                                de alta, baja densidad y
                                Poliolefinas similares. A través de una banda de alimentación hace llegar el material a
                                un dispositivo que tritura y
                                aglomera el material. La película ahí, se calienta mientras es triturada plastificando
                                el material obteniendo un producto
                                que producirá pellets de gran calidad y con una mayor productividad. Este triturador usa
                                un motor AC de gran potencia
                                por lo que pueden ser alimentadas grandes cantidades o tamaños de materiales.
                                El husillo principal está diseñado para una buena compresión, excelente transporte,
                                plastificación y venteo del material.
                                El barril controla la temperatura dela masa plastificada a través de potentes
                                calentadores de hierro de fundición
                                asociados a precisos controladores de temperatura que funcionan en conjunto con potentes
                                ventiladores de bajo ruido
                                para asegurar la calidad de los pellets.
                                El equipo está equipado también con un reductor de velocidad avanzado, de bajo ruido,
                                engranaje de alta dureza y un
                                sistema de lubricación de alimentación forzada, lo cual permite un funcionamiento
                                continuo a alta velocidad
                            </p>
                        </div>
                    </div>
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Apariencia esquemática del equipo</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <img src={Dibujo3d} style={{width: "100%"}}/>
                        </div>
                    </div>


                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Parámetros técnicos</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                1.Juego completo de banda transportadora automática
                                <br/>
                                1.1.) Ancho de la correa: 650mm <br/>
                                1.2.) Longitud de la correa: 4000mm <br/>
                                1.3.) Potencia del motor de la banda: 0.75 KW, utilizando el método de reducción de giro
                                de curva cicloidal<br/>
                                1.4.) Modo de control del motor: control sincrónico con la máquina principal y la
                                trituradora; trabajar o parar<br/>
                                automáticamente según la carga de la máquina principal y la trituradora.<br/>
                                <br/>
                                <br/>
                                2. Conjunto triturador-alimentador. Zona de alimentación, con aleación en el borde del
                                tornillo<br/>
                                2.1) .Material del barril: acero inoxidable.<br/>
                                2.2) Cuchillas fija: 12 piezas<br/>
                                2.3). Cuchillas giratoria: 6 piezas.<br/>
                                2.4.) Material de la cuchilla: Aleación Cr12MoV.<br/>
                                2.5.) Potencia de motor: 75 KW<br/>
                                2.6.) Modo de control del motor: Con inversor ABB<br/>
                                <br/>
                                <br/>
                                3. Extrusora principal<br/>
                                3.1 Características: Extrusora Ø140/28:1<br/>
                                3.1.1) Diámetro del husillo principal: Ø140mm<br/>
                                3.1.2) L/D: 28: 1<br/>
                                3.1.3) Longitud teórica del husillo: 3920 mm<br/>
                                3.1.4) Número de venteos: Dos<br/>
                                3.1.5) Material de tornillo: Acero nitrurado de alta calidad 38CrMoALA, templado,
                                nitrurado el espesor de la capa de
                                nitrógeno es de 0.4-0.7MM, la dureza es más de 940 HV, la fragilidad es el nivel 2.<br/>
                                3.2. Barril principal.<br/>
                                3.2.1) Material del cañón: Acero de nitruración de alta calidad 38CrMoALA. En conjunto,
                                el temple, la nitruración y el
                                espesor de la capa de nitrógeno es 0.4-0.7MM, proporcionan dureza es mayor a 940HV, la
                                fragilidad es el nivel 2.<br/>
                                3.2.2) zonas de calentamiento: 9 zonas<br/>
                                3.2.3) poder de calentamiento: 93 KW<br/>
                                3.2.4) Método de calentamiento: Calentador de fundición de hierro de gran potencia.<br/>
                                3.2.5) Método de enfriamiento: Equipado con un sistema de enfriamiento por anillo de
                                agua para la sección de
                                alimentación, para barriles, se usa ventiladores de alta eficiencia.<br/>
                                3.2.6.) Potencia de enfriamiento: 0.18 KW x 7 zonas = 1.3KW<br/>
                                3.3 Caja reductora.<br/>
                                3.3.1) Modelo de caja de engranajes: Caja de cambios 375<br/>
                                3.3.2) Características de la caja de engranajes:
                                Esta caja de reductora de velocidad cuenta con las características de funcionamiento
                                estable, bajo nivel de
                                ruido, alta capacidad de carga, larga vida útil, fácil de desmontar para verificar el
                                uso, etc.
                                El material del engranaje de la caja de reducción es 20CrMnTi, con proceso de forjado,
                                enfriamiento,
                                carburación. La dureza de la superficie del engranaje es de hasta HRC54 ~ 62,
                                El engranaje es de alta precisión por su proceso de rectificado digital, los engranes
                                contactan suavemente y,
                                por lo tanto, con una alta eficiencia de transmisión.
                                Hay un cojinete de rodillos de auto alineación de gran empuje que puede soportar el
                                empuje axial del tornillo
                                de trabajo.
                                Utiliza una bomba de lubricante para lubricar el lugar necesario y cuenta con un sistema
                                de enfriamiento de
                                agua de alta eficiencia para enfriar el lubricante.
                                <br/>
                                <br/>
                                3.4 Cambia-mallas y cabezal del troquel.<br/>
                                El panel de la matriz (dado) ha sido engrosado y tratado por enfriamiento de alta
                                frecuencia y luego, se pulido para
                                nivelarlo.<br/>
                                3.4.1) Operación de cambia mallas: La doble malla hidráulica, se puede cambiar con la
                                máquina en funcionamiento.<br/>
                                3.4.2) Tamaño de la malla: 280 × 280 mm, forma cuadrada.<br/>
                                3.4.3) Zona de calentamiento: 2 zonas.<br/>
                                3.4.4) Potencia del cabezal: 22 KW<br/>
                                3.4.5) Calentamiento: Con panel de calentamiento de fundición de hierro de alto vacío.
                                3.5 Motor principal.<br/>
                                3.5.1) .Potencia del motor principal: 132 KW<br/>
                                3.5.2.) Modo de control: Con inversores ABB.<br/>
                            </p>
                        </div>
                    </div>
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Características Técnicas Principales del Equipo</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: "black"}}>
                                1.Husillo Auxiliar <br/>
                                1.1) Diámetro del Husillo auxiliar: Ø140mm<br/>
                                1.2) L/D: 12:1<br/>
                                1.3) Longitud teórica del tornillo: 1680mm<br/>
                                1.4) Número de venteos: Uno<br/>
                                1.5) Material del Husillo: Acero nitrurado de alta calidad 40 Cr, con tratamiento de
                                nitruración, el espesor de la capa
                                de nitrógeno es de 0,4 mm a 0,7 mm, la dureza es mayor a 740HV, la fragilidad es el
                                nivel 2.<br/><br/>
                                2.Barril auxiliar<br/>
                                2.1) Zonas de calentamiento: 2 Zonas<br/>
                                2.2) Potencia de calentamiento: 23 KW<br/>
                                2.3) Medios de calentamiento: Elementos de acero inoxidable con calefactores de cuarzo.
                                Método de enfriamiento:
                                Agua, recirculación en tubería de cobre.<br/>
                                2.5) .Material del barril: Acero nitrurado de alta calidad de 40Cr, con tratamiento de
                                nitruración, el espesor de la
                                capa de nitrógeno es de 0,4 mm a 0,7 mm, la dureza es mayor a 740HV, la fragilidad es el
                                nivel 2.<br/><br/>
                                3.Caja de engranajes. Reductor de velocidad<br/>
                                3.1) Tipo de Reductor: Caja de engranajes de bajo ruido<br/>
                                3.2.) Características del reductor:
                                Esta caja de engranajes cuenta con la característica de funcionamiento estable, bajo
                                nivel de ruido, alta capacidad
                                de carga, larga vida útil, fácil de desmontar para revisión y mantenimiento. El material
                                del engranaje de la caja de
                                reducción es 20CrMnTi, con proceso de forjado, enfriamiento, carburación. Y la dureza de
                                la superficie del
                                engranaje es de hasta HRC54 ~ 62, .Después del proceso de rectificado digital, el tamaño
                                del engranaje es de alta
                                precisión, y los engranajes contactan suavemente y, por lo tanto, con una alta
                                eficiencia de transmisión. Hay un
                                cojinete de rodillos de auto alineación de gran empuje que puede soportar el empuje
                                axial del tornillo de trabajo.
                                Utiliza una bomba de aceite para lubricar los sitios necesarios y cuenta con un sistema
                                de enfriamiento de agua de
                                alta eficiencia para enfriamiento de aceite.<br/><br/>
                                4.Cambia mallas y el dado matriz
                                El panel de la matriz ha sido engrosado y tratado por enfriamiento de alta frecuencia. Y
                                luego, pulido para que
                                quede perfectamente nivelado<br/>
                                4.1.) Modo de operación: doble malla hidráulica, se puede cambiar con la máquina en
                                funcionamiento.<br/>
                                4.2) Tamaño de la malla: 280 × 280 mm, forma cuadrada.<br/>
                                4.3) Salida de material: 90 agujeros, ￠ 3.5mm<br/>
                                4.4.) Zona de calentamiento: 2 zonas.<br/>
                                4.5) Potencia de calentamiento: 14KW<br/>
                                4.6) Calentamiento: Con panel de calentamiento de fundición de hierro de alto vatio<br/>
                                5.Motor auxiliar<br/><br/>
                                5.1.) Potencia del motor auxiliar: 30KW<br/>
                                5.2.) Sistema de control: Con inversor ABB.<br/>

                            </p>
                        </div>
                    </div>

                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Dispositivos periféricos</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: "black"}}>
                                1.Dispositivo de corte: Tipo horizontal. <br/>
                                1.1 Dispositivo de corte frontal del troquel<br/>
                                1.2 Material del anillo: acero inoxidable 304 #<br/>
                                1.3 Potencia del dispositivo de corte: 1.5KW,<br/>
                                1.4 Estación de corte: 4 estaciones.<br/><br/>
                                2.Dispositivo de deshidratación: dispositivo de deshidratación centrífuga vertical<br/>
                                2.1 Material del dispositivo: Acero inoxidable<br/>
                                2.2 Potencia del motor: 4 KW – 4 polos<br/>
                                2.3 Potencia de la bomba de agua: 2.2kw<br/><br/>
                                3.Criba vibratoria para clasificar y enfriar el gránulo.<br/>
                                3.1 Vibrador del motor de vibración: 0.25KW<br/>
                                3.2 Malla de diámetro y capa: 8 mm / 2mm. Dos capas.<br/><br/>
                                4.Tolva de almacenamiento de acero inoxidable<br/>
                                4.1. Material del cañón: Acero inoxidable.<br/>
                                4.2 Capacidad de almacenamiento: 250 Kg<br/>
                                4.3 Potencia del motor del ventilador de transporte: 0.9 KW<br/><br/>
                                5.Sistema hidráulico: Estación hidráulica (un juego)<br/>
                                5.1 Potencia del motor: 4 KW - 4 polos<br/>
                                5.2 Grado de aceite hidráulico aplicable: 68 #<br/><br/>
                                6.Sistema eléctrico<br/>
                                6.1. Panel eléctrico: Tipo bastidor, se puede abrir en la parte posterior y
                                frontal.<br/>
                                6.2 Sistema de control de temperatura: Controlador de temperatura RKC de Japón, muy
                                preciso y fácil de operar.<br/>
                                6.3 Contactor Mitsubishi.<br/>
                                6.4 Interruptores marca Chint.<br/><br/>
                                7.Caja de herramientas:<br/>
                                7.1 Ø75mm × 1 tornillo de avance (1 pieza) --- Para desmontar el tornillo;<br/>
                                7.2 Llave Allen 5-17 (7 piezas);<br/>
                                7.3 Llave móvil 8-36 (6 piezas);<br/>
                                7.4 Llave activa 300-36 （1 piezas);<br/>
                                7.5 Destornillador (4 piezas);<br/>
                                7.6 Electro-prueba (1 pieza);<br/>
                                7.5 Funda especial (4 piezas<br/>
                            </p>
                        </div>
                    </div>

                    <div className="services_section_1">
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <div className="parentx">
                                <div className="div1x"><img src={Uno}/></div>
                                <div className="div2x"><img src={Dos}/></div>
                                <div className="div3x"><img src={Tres}/></div>
                                <div className="div4x"><img src={Cuatro}/></div>
                                <div className="div5x"><img src={Cinco}/></div>
                                <div className="div6x"><img src={Seis}/></div>
                                <div className="div7x"><img src={Siete}/></div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </Layout>
    );
};

export default Service_one;
